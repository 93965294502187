<template>
    <label class="toggle">
        <input
            v-model="localModal"
            type="checkbox"
        />
        <span class="toggle__slider"></span>
    </label>
</template>

<script>
import './toggle.scss'

export default {
    name: 'Toggle',
    model: {
        prop: 'model',
        event: 'change',
    },
    props: {
        model: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        localModal: {
            get() {
                return this.$props.model
            },
            set(val) {
                this.$emit('change', val);
            }
        }
    }
}
</script>