<template>
    <Page>
        <template v-slot:title>
            Подписки
        </template>
        <form
            @submit.prevent="saveSettings"
            novalidate
            class="subscription"
        >
            <div class="subscription__item">
                <p class="subscription__title">
                    СМС рассылка
                </p>
                <p class="subscription__desc">
                    Автоматическая рассылка наиболее подходящих вам кредитных предложений
                    на указанный при регистрации номер телефона
                </p>
                <div class="subscription__toggle">
                    <Toggle v-model="props.sms"/>
                </div>
            </div>
            <div class="subscription__item">
                <p class="subscription__title">
                    E-mail рассылка
                </p>
                <p class="subscription__desc">
                    Автоматическая рассылка наиболее подходящих вам кредитных предложений
                    на указанный при регистрации адрес электронной почты
                </p>
                <div class="subscription__toggle">
                    <Toggle v-model="props.email"/>
                </div>
            </div>
            <div class="subscription__item">
                <p class="subscription__title">
                    Защита от непредвиденных ситуаций
                </p>
                <p class="subscription__desc">
                    Включением в список застрахованных лиц.<br/>
                    Стоимость платной услуги 1197 рублей, оплата 3 платежами раз в 5 дней.
                </p>
                <div class="subscription__toggle">
                    <Toggle v-model="props.defence"/>
                </div>
            </div>
            <div class="subscription__action">
                <Button type="submit">
                    Сохранить изменения
                </Button>
            </div>
        </form>
    </Page>
</template>

<script>
import './subscription.scss'

import Toggle from '@/components/form/toggle/Toggle'

import Page from '@/applications/personal-area/layouts/page/Page'

import getSubscriptions from '@/api/getSubscriptions'
import sendSubscriptions from '@/api/sendSubscriptions'

export default {
    name: 'Subscription',
    async created() {
        this.props = await getSubscriptions()
    },
    data() {
        return {
            props: {
                sms: false,
                email: false,
                defence: false,
            }
        }
    },
    methods: {
        saveSettings() {
            sendSubscriptions(this.props);
        },
    },
    components: {
        Page,
        Toggle
    }
}
</script>